/// <reference types="@graphcommerce/next-ui/types" />

import {
  responsiveVal,
  breakpointVal,
  MuiButtonPill,
  MuiButtonInline,
  NextLink,
  MuiButtonResponsive,
  themeBaseDefaults,
  MuiSnackbar,
  MuiChip,
  MuiSlider,
  MuiFabSizes,
  iconStar,
  iconHeart,
} from '@graphcommerce/next-ui'
import { createTheme, Theme, alpha, LinkProps } from '@mui/material'
import { Breakpoints, Components, emphasize, PaletteOptions } from '@mui/material/styles'
import { latoFont } from './localFonts'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    smd: true
  }
  interface TypeBackground {
    elevated: string
  }
}

const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#43B02A',
    contrastText: '#FFFFFF',
    dark: '#348f20',
  },
  secondary: {
    main: '#00A9CE',
    light: '#e3f4f8',
    contrastText: '#ffffff',
  },
  background: {
    default: '#ffffff',
    paper: '#ffffff',
    image: '#ffffff',
    elevated: '#F3F3F3',
  },
  divider: '#041C2C18',
  success: {
    main: '#43B02A',
  },
  error: {
    main: '#D50032',
  },
  action: {
    hoverOpacity: 0.12,
  },
  text: {
    primary: '#041C2C',
    secondary: '#041C2C50',
    disabled: '#041C2C40',
  },
}

const darkPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#43B02A',
    contrastText: '#FFFFFF',
    dark: '#348f20',
  },
  secondary: {
    main: '#0085CA',
    light: '#D1E4FF',
    contrastText: '#ffffff',
  },
  background: {
    default: '#041C2C',
    paper: '#253846',
    image: '#F8F8F8',
    elevated: '#F3F3F3',
  },
  divider: '#ffffff30',
  success: {
    main: '#43B02A',
  },
  error: {
    main: '#D50032',
  },
  action: {
    hoverOpacity: 0.16,
  },
  text: {
    primary: '#ffffff',
    secondary: '#ffffff80',
    disabled: '#ffffff30',
  },
}

const tablePalette: PaletteOptions = {
  primary: {
    main: '#00A9CE',
    light: '#D1E4FF',
    contrastText: '#ffffff',
  },
}

const breakpointValues: Breakpoints['values'] = {
  xs: 0,
  sm: 600,
  smd: 768,
  md: 960,
  lg: 1536,
  xl: 1920,
}

const fontSize = (from: number, to: number) => breakpointVal('fontSize', from, to, breakpointValues)

// Create a theme instance.
const createThemeWithPalette = (palette: PaletteOptions) =>
  createTheme({
    palette,
    ...themeBaseDefaults,
    breakpoints: {
      values: breakpointValues,
    },
    shape: { borderRadius: 3 },
    typography: {
      fontFamily: latoFont.style.fontFamily,
      // @see docs typography.md
      h1: {
        fontFamily: 'Bw Mitga,sans-serif',
        ...fontSize(25, 40),
        fontWeight: 700,
        lineHeight: 1.35,
      },
      h2: {
        fontFamily: 'Bw Mitga,sans-serif',
        ...fontSize(22, 30),
        fontWeight: 700,
        lineHeight: 1.55,
      },
      h3: {
        fontFamily: 'Bw Mitga,sans-serif',
        ...fontSize(18, 22),
        fontWeight: 500,
        lineHeight: 1.55,
      },
      h4: {
        fontFamily: 'Bw Mitga,sans-serif',
        ...fontSize(17, 20),
        fontWeight: 700,
        lineHeight: 1.55,
      },
      h5: {
        ...fontSize(17, 20),
        fontWeight: 550,
        lineHeight: 1.8,
      },
      h6: {
        ...fontSize(16, 19),
        fontWeight: 550,
        lineHeight: 1.8,
      },

      subtitle1: {
        ...fontSize(16, 19),
        fontWeight: 400,
        lineHeight: 1.7,
      },
      fontWeightBold: 600,
      body1: {
        ...fontSize(14, 18),
        lineHeight: 1.7,
      },
      subtitle2: {
        ...fontSize(14, 16),
        fontWeight: 500,
        lineHeight: 1.7,
      },
      body2: {
        ...fontSize(13, 15),
        lineHeight: 1.7,
      },
      caption: {
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        ...fontSize(12, 13),
      },
      button: {},
      overline: {
        // https://web.dev/font-size/#how-the-lighthouse-font-size-audit-fails
        ...fontSize(12, 14),
        fontWeight: 500,
        letterSpacing: 1,
        lineHeight: 1.2,
        textTransform: 'uppercase',
      },
    },
    spacings: {
      xxs: responsiveVal(8, 12),
      xs: responsiveVal(10, 16),
      sm: responsiveVal(12, 20),
      md: responsiveVal(14, 30),
      lg: responsiveVal(20, 60),
      xl: responsiveVal(24, 80),
      xxl: responsiveVal(40, 100),
    },
    page: {
      horizontal: responsiveVal(10, 30),
      vertical: responsiveVal(10, 30),
    },
    appShell: {
      headerHeightSm: '46px',
      headerHeightMd: '100px',
      appBarHeightMd: '80px',
      appBarInnerHeightMd: '46px',
    },
  })

// todo: move most of the styles to the graphcommerce library while still allowing for extensibility.
const createOverrides = (theme: Theme): Components => ({
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        overflowY: 'scroll',
        '& .ProductWishlistChipBase-wishlistButton': {
          background: theme.palette.background.elevated,
          boxShadow: 'none',
        },
        '& .ProductWishlistChipBase-wishlistIconActive': {
          color: theme.palette.text.primary,
        },
        '& .ProductWishlistChipBase-wishlistIcon': {
          color: theme.palette.text.primary,
          fill: 'transparent',
        },
      },
      '::selection': { background: alpha(theme.palette.primary.main, 0.6) },
      '::-moz-selection': { background: alpha(theme.palette.primary.main, 0.6) },
      '#__next': {
        position: 'relative',
      },
      img: {
        filter: 'brightness(1.03)',
      },
      '@font-face': {
        fontFamily: 'Bw Mitga',
        fontStyle: 'normal',
        fontWeight: 700,
        fontDisplay: 'swap',
        src: `url("/fonts/BwMitgaBlack/BwMitgaBlack.woff2")`,
      },
      '#__next-build-watcher': { bottom: '50px !important' },
    },
  },

  // https://mui.com/material-ui/guides/routing/#global-theme-link
  // https://www.graphcommerce.org/docs/framework/links
  MuiLink: { defaultProps: { component: NextLink } as LinkProps },
  MuiButtonBase: { defaultProps: { LinkComponent: NextLink } },

  MuiContainer: {
    variants: [
      {
        props: { disableGutters: false },
        style: {
          paddingLeft: theme.page.horizontal,
          paddingRight: theme.page.horizontal,
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.page.horizontal,
            paddingRight: theme.page.horizontal,
          },
        },
      },
    ],
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: '16px', // https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
      },
    },
  },

  MuiButton: {
    defaultProps: { color: 'inherit' },
    variants: [
      ...MuiButtonResponsive,
      ...MuiButtonPill,
      ...MuiButtonInline,
      {
        props: { variant: 'contained', color: 'inherit' },
        style: {
          backgroundColor: theme.palette.background.paper,
          '&:hover': {
            backgroundColor: emphasize(theme.palette.background.paper, 0.1),
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          borderRadius: responsiveVal(theme.shape.borderRadius * 3, theme.shape.borderRadius * 4),
        },
      },
      {
        props: { variant: 'text' },
        style: { borderRadius: '99em' },
      },
      {
        props: { variant: 'inline' },
        style: { borderRadius: '99em' },
      },
      {
        props: { color: 'primary' },
        style: {
          '&:not(.Mui-disabled)': {
            boxShadow: 'none',
          },
        },
      },
      {
        props: { color: 'secondary' },
        style: {
          '&:not(.Mui-disabled)': {
            boxShadow: 'none',
          },
        },
      },
    ],
  },

  MuiFab: {
    styleOverrides: {
      root: {
        '&.MuiFab-default': {
          backgroundColor: theme.palette.background.paper,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
          },
          color: theme.palette.text.primary,
        },
      },
      colorInherit: {
        backgroundColor: 'inherit',
        '&:hover, &:focus': {
          backgroundColor: 'inherit',
        },
        boxShadow: 'none',
      },
      extended: {
        fontWeight: 400,
        textTransform: 'none',
      },
    },
    variants: [...MuiFabSizes],
  },

  MuiTextField: {
    // defaultProps: { color: 'secondary' },
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 1.5,
            theme.shape.borderRadius * 2,
            theme.breakpoints.values,
          ),
        },
      },
    },
  },

  // MuiTextField: {
  //   styleOverrides: {
  //     root: {
  //       '& label.Mui-focused': {
  //         color: theme.palette.secondary.main,
  //       },
  //       '& .MuiOutlinedInput-root': {
  //         borderRadius: responsiveVal(theme.shape.borderRadius * 1.5, theme.shape.borderRadius * 2),
  //         '& fieldset': {
  //           borderColor: theme.palette.divider,
  //         },
  //       },
  //       '&.Mui-focused fieldset': {
  //         borderColor: theme.palette.divider,
  //         borderWidth: 1,
  //       },
  //     },
  //   },
  // },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },

  MuiChip: {
    variants: [...MuiChip],
  },

  MuiCheckbox: {
    styleOverrides: {
      colorPrimary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },
      colorSecondary: {
        color: theme.palette.text.disabled,
        '&.Mui-checked': {
          color: theme.palette.secondary.main,
        },
      },
    },

    variants: [
      {
        props: { size: 'medium' },
        style: {
          padding: 7,
        },
      },
    ],
  },

  MuiSwitch: {
    styleOverrides: {
      thumb: {
        boxShadow: theme.shadows[6],
      },
    },
  },

  MuiSnackbar: { variants: [...MuiSnackbar] },

  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        backgroundColor: theme.palette.text.disabled,
      },
    },
  },

  MuiSlider: { variants: [...MuiSlider] },

  IconSvg: {
    overrides: [[iconHeart, iconStar]],
  },

  MuiCircularProgress: {
    defaultProps: {
      thickness: 2,
    },
  },

  AddProductsToCartForm: {
    defaultProps: {
      redirect: 'added',
    },
  },
})

export const lightTheme = createThemeWithPalette(lightPalette)
lightTheme.components = createOverrides(lightTheme)

export const darkTheme = createThemeWithPalette(darkPalette)
darkTheme.components = createOverrides(darkTheme)

export const tableTheme = createThemeWithPalette(tablePalette)
tableTheme.components = createOverrides(tableTheme)
