import { GlobalStyles } from '@mui/material'
import Script from 'next/script'

export const CookieNotice = () => (
  <>
    <GlobalStyles
      styles={{
        '.cookiefirst-root [data-cookiefirst-action="open-preferences"]': {
          display: 'none',
        },
      }}
    />
    <Script
      id='cookie-first'
      src='https://consent.cookiefirst.com/sites/verdouw.nu-c06efb65-8749-4550-9c9d-943859e274ea/consent.js'
      strategy='lazyOnload'
    />
  </>
)
