import { ApolloLink, onError, setContext } from '@graphcommerce/graphql'
import { CustomerTokenDocument } from '@graphcommerce/magento-customer'

export const OP_MAAT_HEADER = 'x-authorization-opmaat'
export const OP_MAAT_USER_TOKEN_ID = 'x-authorization-opmaat-user-token-id'

function rewriteNewToken(operation, err) {
  const context = operation.getContext()
  const tokenQuery = context.cache.readQuery({ query: CustomerTokenDocument })

  if (!tokenQuery?.customerToken) return

  context.cache.writeQuery({
    query: CustomerTokenDocument,
    data: {
      customerToken: {
        ...tokenQuery?.customerToken,
        opMaatUserIdToken: '',
        jwtToken: err.extensions.token as string,
        valid: true,
      },
    },
  })

  const oldHeaders = operation.getContext().headers
  operation.setContext({
    headers: {
      ...oldHeaders,
      [OP_MAAT_HEADER]: `Bearer ${err.extensions.token}`,
    },
  })
}

const addTokenHeader = setContext((_, context) => {
  if (!context.headers) context.headers = {}
  try {
    const query = context.cache.readQuery({ query: CustomerTokenDocument })

    if (query?.customerToken?.jwtToken) {
      context.headers[OP_MAAT_HEADER] = `Bearer ${query.customerToken.jwtToken}`
      if (query.customerToken.opMaatUserIdToken)
        context.headers[OP_MAAT_USER_TOKEN_ID] = `Bearer ${query.customerToken.opMaatUserIdToken}`

      return context
    }
    return context
  } catch (error) {
    return context
  }
})

export const onForbiddenError = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const { cache } = operation.getContext()

      if (err.extensions?.category === 'graphql-opmaat-authorization') {
        const res = cache.readQuery({ query: CustomerTokenDocument })

        if (res?.customerToken?.valid) {
          // Write arbitrary old token to document
          cache.writeQuery({
            query: CustomerTokenDocument,
            data: {
              customerToken: {
                ...res.customerToken,
                valid: false,
              },
            },
            broadcast: true,
          })
        }
      }
      if (err.extensions?.category === 'graphql-retry-with-token' && err.extensions.token) {
        rewriteNewToken(operation, err)
        return forward(operation)
      }

      if (
        err.extensions?.category === 'graphql-switch-token-on-association' &&
        err.extensions.token
      ) {
        rewriteNewToken(operation, err)
      }
    }
  }
  return undefined
})

export const opMaatCustomerTokenLink = ApolloLink.from([addTokenHeader, onForbiddenError])
