import type { FieldPolicy, StrictTypedTypePolicies } from '@graphcommerce/graphql'
import type { Mutation } from '@graphcommerce/graphql-mesh'
import { CustomerTokenDocument } from '@graphcommerce/magento-customer'

const TOKEN_EXPIRATION_MS = 60 * 60 * 1000

const generateCustomerToken: FieldPolicy<Mutation['generateCustomerToken']> = {
  keyArgs: () => '',
  merge(_existing, incoming, options) {
    if (!options.isReference(incoming)) return incoming

    const write = () => {
      options.cache.writeQuery({
        query: CustomerTokenDocument,
        broadcast: true,
        data: {
          customerToken: {
            __typename: 'CustomerToken',
            token: options.readField('token', incoming) as string,
            createdAt: new Date().toUTCString(),
            valid: true,
            opMaatNonUserIdToken: options.readField('opMaatNonUserIdToken', incoming) as string,
            opMaatUserIdToken: options.readField('opMaatUserIdToken', incoming) as string,
            jwtToken: options.readField('jwtToken', incoming) as string,
            email: options.readField('email', incoming) as string,
          },
        },
      })
    }
    write()

    // Broadcasts the query after the token expiration so UI gets updated
    setTimeout(write, TOKEN_EXPIRATION_MS)
    return incoming
  },
}

export const opMaatTypePolicies: StrictTypedTypePolicies = {
  Mutation: { fields: { generateCustomerToken } },
}
