import { FramerNextPages } from '@graphcommerce/framer-next-pages'
import { GraphQLProvider } from '@graphcommerce/graphql'
import { GlobalHead } from '@graphcommerce/magento-store'
import { CssAndFramerMotionProvider, PageLoadIndicator } from '@graphcommerce/next-ui'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { AppProps } from 'next/app'
import { CookieNotice } from '../components/CookieFirst/CookieNotice'
import { CustomerChat } from '../components/Zendesk/CustomerServiceChat'
import { lightTheme } from '../components/theme'
import { opMaatCustomerTokenLink } from '../components/useCustomerStatus/opMaatCustomerTokenLink'
import { opMaatTypePolicies } from '../components/useCustomerStatus/typePolicies'
import { I18nProvider } from '../lib/i18n/I18nProvider'

export default function ThemedApp(props: AppProps) {
  const { router } = props
  const { locale = 'en' } = router

  return (
    <main>
      <CssAndFramerMotionProvider {...props}>
        <I18nProvider key={locale} locale={locale}>
          <GraphQLProvider
            {...props}
            links={[opMaatCustomerTokenLink]}
            policies={[opMaatTypePolicies]}
          >
            <ThemeProvider theme={lightTheme}>
              <GlobalHead />
              <CssBaseline />
              <PageLoadIndicator />

              <FramerNextPages {...props} />
              <CustomerChat />
              <CookieNotice />
              {/* <SessionDebugger /> */}
            </ThemeProvider>
          </GraphQLProvider>
        </I18nProvider>
      </CssAndFramerMotionProvider>
    </main>
  )
}
