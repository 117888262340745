import { Theme, useMediaQuery } from '@mui/material'
import Script from 'next/script'
import { useEffect, useState } from 'react'

export const CustomerChat = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!isMobile && mounted) {
    return (
      <Script
        id='zendesk-script'
        dangerouslySetInnerHTML={{
          __html: `
            (function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
_.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");
$.src="https://v2.zopim.com/?3iCZvAhVaHIq0VAhxQQulyAhLYnv3s7g";z.t=+new Date;$.
type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");`,
        }}
        strategy='lazyOnload'
      />
    )
  }

  return null
}
